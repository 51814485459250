'use strict';
/**
 *  Load modules
 */

import echo from './modules/echo.js';
import slick from './modules/slick.js';
import tabs from './modules/tabs.js';
import filter from './modules/filter.js';
import stickyHeader from './modules/stickyHeader.js';
import accordion from './modules/accordion.js';
import '../scss/app.scss';
$(document).ready(function () {
  var body = document.querySelector('body');
  var burgerMenu = document.querySelector('.nav-opener');
  burgerMenu.addEventListener('click', function (e) {
    e.preventDefault();
    body.classList.toggle('nav-active');
  });
  var heroSwiperAutoplay = 0;

  if (settings.home_billboard_slider_autoplay) {
    heroSwiperAutoplay = settings.home_billboard_slider_autoplay;
  }

  if (heroSwiperAutoplay) {
    var autoplayHeroSlider = {
      delay: 8000,
      disableOnInteraction: false
    };
  } else {
    var autoplayHeroSlider = false;
  }

  var heroSwiper = new Swiper('.hero__swiper', {
    effect: 'fade',
    loop: true,
    autoplay: autoplayHeroSlider
  });
  var swiperBoxAutoplay = 0;

  if (settings.transition_swiper_box_autoplay) {
    swiperBoxAutoplay = settings.transition_swiper_box_autoplay;
  }

  if (swiperBoxAutoplay) {
    var autoplaySwiperBox = {
      delay: 8000,
      disableOnInteraction: false
    };
  } else {
    var autoplaySwiperBox = false;
  }

  var galleryThumbs = new Swiper('.swiper-box', {
    slidesPerView: 1,
    loop: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: autoplaySwiperBox
  });

  if ($('.gallery-top')[0]) {
    var galleryTop = new Swiper('.gallery-top', {
      loop: true
    });

    if (jQuery(window).width() < 768) {
      galleryTop.controller.control = galleryThumbs;
      galleryThumbs.controller.control = galleryTop;
    }
  }

  jQuery('.project__filter').each(function () {
    var holder = jQuery(this);
    holder.find('.project__subcategory').hide();
    holder.find('.project__subcategory button').on('click', function () {
      var button = jQuery(this);
      holder.find('.project__subcategory button').removeClass('active');
      button.addClass('active');
    });
    holder.find('.project__category button').on('click', function () {
      var button = jQuery(this);
      var buttonAttr = button.attr('data-filter').toLowerCase();
      holder.find('.project__subcategory').hide();
      holder.find('.project__category button').removeClass('active');
      button.addClass('active');

      if (buttonAttr === 'all') {
        holder.find('.project__subcategory').hide();
      }

      holder.find('.project__subcategory').each(function () {
        var subcategory = jQuery(this);
        var subcategoryAttr = subcategory.attr('data-subcategory').toLowerCase();

        if (buttonAttr === subcategoryAttr) {
          subcategory.show();
        }
      });
    });
  });
  jQuery('.project__slider').each(function () {
    var holder = jQuery(this);
    holder.find('.subcategory-slider').hide();
    holder.find('.subcategory-slider button').on('click', function () {
      var button = jQuery(this);
      holder.find('.subcategory-slider button').removeClass('active');
      button.addClass('active');
    });
    holder.find('.slider-main button').on('click', function () {
      var button = jQuery(this);
      var buttonAttr = button.attr('value');
      holder.find('.subcategory-slider').hide();
      holder.find('.slider-main button').removeClass('active');
      button.addClass('active');

      if (buttonAttr === 'all') {
        holder.find('.subcategory-slider').hide();
      }

      holder.find('.subcategory-slider').each(function () {
        var subcategory = jQuery(this);
        var subcategoryAttr = subcategory.attr('data-subcategory').toLowerCase();

        if (buttonAttr === subcategoryAttr) {
          subcategory.show();
        }
      });
    });
  });
  jQuery('.contact .wpcf7-validates-as-required').each(function () {
    var validate = jQuery(this);
    var placeholder = validate.attr('placeholder');

    if (validate) {
      var span = '<span class="validate-decor">' + placeholder + '<span>' + '*' + '</span>' + '</span>';
      validate.parent().append(span);
    }

    validate.on('focus', function () {
      jQuery(this).siblings('.validate-decor').addClass('focus');
    });
    validate.on('blur', function () {
      if (validate.val() === '') {
        jQuery(this).siblings('.validate-decor').removeClass('focus');
      }
    });
  });
  jQuery('.gallery-top .opener').on('click', function (e) {
    e.preventDefault();
    jQuery('.swiper-holder').toggleClass('active');
  });
  jQuery('.swiper-box .opener').on('click', function (e) {
    e.preventDefault();
    jQuery('.swiper-holder').toggleClass('active');
  });

  if ($(".project__filter .project__category .active")[0]) {
    $(".project__filter .project__category .active").trigger("click");
  }

  if ($(".project__filter .project__subcategory .active")[0]) {
    $(".project__filter .project__subcategory .active").trigger("click");
  }

  if ($(".project__slider .project__slider-list.slider-main .active")[0]) {
    $(".project__slider .project__slider-list.slider-main .active").trigger("click");
  }

  if ($(".project__slider .project__slider-list.subcategory-slider .active")[0]) {
    $(".project__slider .project__slider-list.subcategory-slider .active").trigger("click");
  }

  swiperStylesHeroHome();
});

function swiperStylesHeroHome() {
  var swiperHolder = $(".swiper-holder"),
      swiperHolderHeight = swiperHolder.outerHeight(),
      swiperHolderHalfHeight = swiperHolderHeight / 2,
      headerHeight = $(".header").outerHeight(),
      heroContainer = $("body.home .hero .container"),
      blogLink = $("body.home .service .blog-link");

  if (window.matchMedia('(min-width: 768px)').matches) {
    heroContainer.css("min-height", $(window).height() - swiperHolderHalfHeight - headerHeight);
    swiperHolder.css("margin-top", "-" + swiperHolderHalfHeight + "px");
    blogLink.css("top", "-" + swiperHolderHeight + "px");
  } else {
    heroContainer.css("min-height", "auto");
    swiperHolder.css("margin-top", "0");
    blogLink.css("top", "85px");
  } // console.log(swiperHolderHeight);
  // console.log(headerHeight);
  // console.log($(window).height());

}

$(window).on("resize orientationchange", function () {
  setTimeout(swiperStylesHeroHome, 500);
});
$(document).on('click', '.team__open', function (e) {
  e.preventDefault();
  var $this = $(this),
      team_member_id = $this.data('id'),
      tabset_number = $this.parents(".tabset").data('tabset'),
      section = $this.parents(".detail-team"),
      page_id = section.data('page_id');
  $.ajax({
    url: settings.ajax_url,
    type: 'post',
    data: {
      action: 'team_member',
      team_member_id: team_member_id,
      page_id: page_id
    },
    beforeSend: function beforeSend(xhr) {},
    success: function success(response) {
      $(".team__open[data-id!=" + team_member_id + "]").removeClass("active");
      $this.toggleClass("active");
      section.addClass("active");

      if (!$(".team__open").hasClass("active")) {
        section.removeClass("active");
      }

      if (window.matchMedia('(min-width: 768px)').matches) {
        if ($this.hasClass("active")) {
          $('#tabset-' + tabset_number).next().hide().html(response).fadeIn(1000);
        } else {
          $('#tabset-' + tabset_number).next().html(response).slideUp();
        }

        $('html, body').animate({
          scrollTop: $('#tabset-' + tabset_number).offset().top - $("header").outerHeight()
        }, 'slow');

        var removeShowingTeamDesktop = function removeShowingTeamDesktop() {
          $(".team__content[data-tabset!=" + tabset_number + "]").slideUp();
        };

        setTimeout(removeShowingTeamDesktop, 800);
      } else {
        if ($this.hasClass("active")) {
          $this.parents(".team__col").next().html(response).fadeIn();
        } else {
          $this.parents(".team__col").next().html(response).slideUp();
        }

        $('html, body').animate({
          scrollTop: $this.parents(".team__col").next().offset().top - $("header").outerHeight() - $this.parents(".team__col").outerHeight()
        }, 'slow');

        var removeShowingTeam = function removeShowingTeam() {
          $(".team__content--mobile[data-id!=" + team_member_id + "]").slideUp();
        };

        setTimeout(removeShowingTeam, 800);
      }
    }
  }).done(function () {});
});