jQuery(function () {
  function filter() {
    jQuery('.filter-container').each(function () {
      var holder = jQuery(this);
      var filterContainer = holder.find('.project__row');
      var items = holder.find('.filtr-item');
      var btnMore = holder.siblings('.project__load-more').find('a');
      var loadCount = 1;
      var visibleItems = 8;
      var rows = 2;
      filterContainer.filterizr({
        callbacks: {
          onInit: function onInit() {
            if (items.length > visibleItems) {
              holder.css({
                height: items.outerHeight() * rows
              });
            } else {
              btnMore.hide();
            }

            recalculateHeight();
          },
          onFilteringEnd: function onFilteringEnd() {
            window.history.pushState(null, null, window.location.pathname);
            refreshState(items.not('.filteredOut'));
            setTimeout(function () {
              refreshState(items.not('.filteredOut'));
            }, 500);
            recalculateHeight();
            setTimeout(recalculateHeight, 500);
          }
        }
      });

      function refreshState(activeItems) {
        if (activeItems.length > visibleItems) {
          btnMore.show();
          holder.css({
            height: items.outerHeight() * rows
          });
        } else {
          holder.removeAttr('style');
          btnMore.hide();
        }
      }

      function recalculateHeight() {
        var newHeight = items.outerHeight() * rows * loadCount;

        if (newHeight >= filterContainer.outerHeight()) {
          newHeight = filterContainer.outerHeight();
          btnMore.hide();
        }

        holder.css({
          height: newHeight
        });
      }

      btnMore.on('click', function (e) {
        e.preventDefault();
        ++loadCount;
        recalculateHeight();
      });
      jQuery(window).on('resize orientationchange', function () {
        setTimeout(recalculateHeight, 100);
      });
    });
  }

  filter();
  jQuery(window).on('resize orientationchange', filter);
});